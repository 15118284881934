<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="700px"
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="5" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Payment description
          </div>
        </v-col>
      </v-row>
      <v-form class="pb-20" ref="form">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10" cols="12" sm="5">
            <span class="font-normal nameField--text text-sm-right">Description</span>
          </v-col>

          <v-col class="pb-8 pb-sm-10" cols="12" sm="7">
            <FormTextarea
              v-model="formData.description"
              readonly
            />
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Close</CancelButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import FormTextarea from '@/components/inputs/FormTextarea'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

export default {
  name: 'DialogPaymentDescription',
  inheritAttrs: false,
  components: { FormTextarea, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    description: {
      type: String | Number,
      default: () => ({})
    }
  },
  data: () => ({
    formData: {
      description: null,
    },
    rules: []
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.formData.description = this.description
      } else {
        this.$refs.form.reset()
      }
    }
  }
}
</script>