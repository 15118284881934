<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Payment</div>
      </portal>
  
      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :to="{ name: 'FillBalance'}">
        Fill Balance
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <v-spacer />
      
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
      
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>
    
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #item.amount="{ item }">
          <span
            class="font-normal text-no-wrap"
            :class="getColor(item.type)"
          >
            {{ currency(item.amount) }}
          </span>
        </template>

        <template #item.type="{ item }">
          <span class="font-normal text-no-wrap">
            {{ getType(item.type) }}
          </span>
        </template>

        <template #item.actions="{ item }">
          <TooltipAction bottom message="Show Description">
            <template #activator>
              <v-icon class="mr-6" @click="openDialogDescription(item)">$descriptionIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>

    <DialogPaymentDescription
      v-model="dialog"
      :description="dialogDescription"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SearchField from '@/components/inputs/SearchField'
import SelectOnPage from '@/components/select/SelectOnPage'
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogPaymentDescription from '@/components/dialog/DialogPaymentDescription'
import TablePagination from '@/components/pagination/TablePagination'

import currency from '@/mixins/currency'

import { getPaymentHistory } from '@/api/setting-routes'
import { getOnPage, parsePaginationData } from '@/helper/app-helper'

export default {
  name: 'PaymentHistory',
  components: { ActionOutlinedButton, RefreshButton, SearchField, SelectOnPage, TooltipAction, DialogPaymentDescription, TablePagination },
  mixins: [ currency ],
  data: () => ({
    loading: false,
    dialog: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1,
    },
    options: {},
    headers: [
      { text: 'Date', value: 'date', sortable: false },
      { text: 'Amount', value: 'amount', sortable: false },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'Actions', value: 'actions', align: 'end', sortable: false },
    ],
    serverSorting: null,
    search: null,
    dialogDescription: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    async loadPaymentHistory() {
      this.loading = true

      const { success, payload, message } = await getPaymentHistory({
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        ...this.serverSorting,
      })

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadPaymentHistory()
      }
      this.$vuetify.goTo(0)
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadPaymentHistory()
    },
    openDialogDescription(item) {
      this.dialogDescription = item?.description ? item.description : ''
      this.dialog = true
    },
    getType(type) {
      return !!type ? type.name : '-//-'
    },
    getColor(type) {
      if (!type) {
        return 'completeStatus--text'
      }
      switch (type.id) {
        case 3:
          return 'yellow--text'
        case 4:
        case 5:
          return 'pink--text'
        default:
          return 'completeStatus--text'

      }
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadPaymentHistory()
      },
    },
  },
}
</script>
